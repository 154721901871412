import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getRequest, patchRequest } from '../../apis/functions';
import APIS from '../../apis/routes';
import { toaster } from '../../apis/commonFunctions';
import Button from '../../common/Button';
import Image from '../../common/Image';
import Dropdown from '../../common/Dropdown'
import Input from '../../common/Input';
import Loader from '../Loader';
import moment from 'moment';
import { CancelChip, DeleteIcon, EditData, EditIcon, Hotel, ImagesIcon, Location, MealIcon, More, TourCar, VideosIcon } from '../../common/Icons';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import AWS from 'aws-sdk';
import UploadImages from '../UploadImages';
import UploadVideo from '../UploadVideo';
import { airports } from '@nwpr/airport-codes';

const Chip = ({ text }) => {
    return <div style={{ "display": "inlineBlock", padding: "8px 12px", "borderRadius": "16px", backgroundColor: "#e0e0e0", color: "#333", fontFamily: "Arial, sans-serif", fontSize: "14px", margin: "5px", width: "fit-content" }}>
        {text}
    </div>
}



const ItineraryDetails = () => {
    const nav = useNavigate()
    const info = useSelector(state => state.details)
    const [details, setDetails] = useState({});
    const [load, setLoad] = useState(true)
    const inputRef = useRef(null);
    const inputRefs = useRef([]);
    const inputDestination = useRef(null);
    const [edit, setEdit] = useState(false)
    const [tabName, setTabName] = useState('DATES')
    const [departure, setDeparture] = useState([])
    const [arrival, setArrival] = useState([])
    const [error, setError] = useState(false)
    const [checked, setChecked] = useState(false)
    const [indexEdit, setIndexEdit] = useState(null)
    const [indexDelete, setIndexDelete] = useState(null)
    const [pendingAcc, setPendingAcc] = useState(0)
    const [searchLoc, setSearchLoc] = useState('')
    const [obj, setObj] = useState({
        destination_address: "",
        no_of_days: 0,
        no_of_nights: 0,
        start_date: 0,
        end_date: 0,
        confirmation_number: "",
        departure_station: "",
        number_of_people: 0,
        arrival_station: "",
        description: "",
        charges: 0,
        tab_name: tabName
    });
    const [acc, setAcc] = useState([])
    const [address, setAddress] = useState('')
    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)
    const [meals_name, setMealsName] = useState([])
    const [data, setData] = useState({ meals_name: '', tours_name: '' })



    const inputEvent = (e) => {
        const { name, value } = e.target;

        // Convert value to a number for comparison and setting
        const numericValue = parseInt(value, 10);

        if (name === 'no_of_days' || name === 'no_of_nights' || name === 'charges') {
            if (isNaN(numericValue)) {
                return;
            }
        }

        switch (name) {
            case 'no_of_days':
                if (isNaN(numericValue) || numericValue > 14) {
                    toast.error('Number of days should not be greater than 14');
                    return;
                } else if (numericValue <= 0) {
                    toast.error('Number of days should not be zero or negative');
                    return;
                }
                setObj(prevDetails => ({
                    ...prevDetails,
                    no_of_days: numericValue,
                    no_of_nights: numericValue - 1
                }));
                break;

            case 'no_of_nights':
                if (isNaN(numericValue) || numericValue <= 0) {
                    toast.error('Number of nights should not be zero or negative');
                    return;
                }
                setObj(prevDetails => ({
                    ...prevDetails,
                    no_of_nights: numericValue
                }));
                break;

            case 'number_of_people':
                if (isNaN(numericValue) || numericValue <= 0) {
                    toast.error('Number of nights should not be zero or negative');
                    return;
                }
                setObj(prevDetails => ({
                    ...prevDetails,
                    number_of_people: numericValue
                }));
                break;

            case 'charges':
                if (isNaN(numericValue)) {
                    toast.error('Charges must be a valid number');
                    return;
                }
                setObj(prevDetails => ({
                    ...prevDetails,
                    charges: numericValue
                }));
                break;

            case 'month':
            case 'start_date':
            case 'end_date':
                // Handle date input: convert to timestamp if needed
                const dateValue = new Date(value).getTime(); // Assuming value is a valid date string
                setObj(prevObj => ({
                    ...prevObj,
                    [name]: dateValue
                }));
                break;
            default:
                setObj(prevObj => ({
                    ...prevObj,
                    [name]: value
                }));
                break;
        }
    };



    const handleAddData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        })
    };



    const handlePushMeals = () => {
        setMealsName(prevMealsName => [...prevMealsName, data.meals_name]);
        setData({ meals_name: '' })
    };

    const handleRemoveMeal = (itemToRemove) => {
        const updatedItems = meals_name.filter((item, index) => index !== itemToRemove);
        setMealsName(updatedItems);
    }
    const handleDeleteImages = (image, itemToRemove) => {
        try {
            const parts = image.split('/');
            // Extract the last part of the URL (which is the key)
            const key = parts[parts.length - 1];

            AWS.config.update({
                accessKeyId: 'AKIA32MQLJXNBHHKIR5J',
                secretAccessKey: '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',
                region: 'us-east-1'
            });
            const s3 = new AWS.S3();
            const params = {
                Bucket: 'danna-multimedia-s3',
                Key: key // Specify the key (name) of the object you want to delete
            };

            s3.deleteObject(params, (err) => {
                if (err) {
                    toast.error('Error deleting object:', err);
                    return;
                } else {
                    if (itemToRemove === null) {
                        setObj(prevDetails => ({
                            ...prevDetails, video: ''
                        }));
                        setDetails(prevDetails => ({
                            ...prevDetails, video: ''
                        }));
                        setEdit(true)
                    } else {
                        const updatedItems = obj.photos && obj.photos.filter((item, index) => index !== itemToRemove);
                        const updatedItems2 = details.photos && details.photos.filter((item, index) => index !== itemToRemove);
                        setObj(prevDetails => ({
                            ...prevDetails, photos: updatedItems
                        }));
                        setDetails(prevDetails => ({
                            ...prevDetails, photos: updatedItems
                        }));
                        setEdit(true)
                    }
                    toast.success('Deleted successfully:');
                }
            });

        } catch (error) {
            toast.error(error.message)
        }

    }
    const handleCheckData = () => {
        setChecked(true)
        const accommodationData = {
            accomondation_address: address,
            tours: data.tours_name,
            meals: meals_name,
            location: {
                type: "Point",
                coordinates: [longitude, latitude]
            }
        }
        setObj(prevDetails => ({
            ...prevDetails,
            accomondations: [...prevDetails.accomondations, accommodationData]
        }));
    }



    const handleMore = () => {
        setAcc([{
            accomondation_address: "",
            tours: "",
            meals: [''],
            location: {
                type: "Point",
                coordinates: [0, 0]
            }
        }])
    }



    const initMap = () => {
        const input = inputRef.current;

        // Create the autocomplete object, restricting the search to lodging (hotels) only
        const autocomplete = new window.google.maps.places.Autocomplete(input, {
            types: ['lodging'], // This restricts to establishments (hotels are included)
        });

        // Bias the results to only show hotels
        autocomplete.setFields(['place_id', 'geometry', 'name', 'formatted_address', 'types']);

        // Listen for the event when the user selects a prediction
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            console.log(place)

            if (!place.geometry || !place.types.includes('lodging')) {
                setError(true); // Handle case where no lodging (hotel) is selected
                return;
            }

            // Use the selected place to update state
            setSearchLoc(place.name)
            setAddress(place.name);
            setLatitude(place.geometry.location.lat());
            setLongitude(place.geometry.location.lng());
            setError(false);
        });
    };



    const handleSearchLoc = (e) => {
        setSearchLoc(e.target.value)
    }


    const handleAddAirport = (name, city, key) => {
        setObj(prevDetails => ({
            ...prevDetails, [key]: name + " " + city
        }));
        setDeparture([])
        setArrival([])
    }


    useEffect(() => {
        initMap();
    }, [searchLoc]);


    const { id } = useParams();

    const handleEditAccommodation = (data, index) => {
        setIndexEdit(index)
        setMealsName(data.meals)
        setData({ meals_name: '', tours_name: data.tours })
        setSearchLoc(data.accomondation_address)
        setAddress(data.accomondation_address)
        setLatitude(data.location.coordinates[1])
        setLongitude(data.location.coordinates[0])
    }

    const handleSaveAccommodation = () => {
        const newEditData = {
            accomondation_address: address,
            tours: data.tours_name,
            meals: meals_name,
            location: {
                type: "Point",
                coordinates: [longitude, latitude]
            }
        }
        if (indexEdit >= 0 && indexEdit < details.accomondations.length) {
            details.accomondations[indexEdit] = { ...details.accomondations[indexEdit], ...newEditData };
            setIndexEdit(null)
            updateDescription()
        } else {
            console.log("Index out of bounds");
        }
    }

    const deleteAccommodationObject = () => {
        if (indexDelete >= 0 && indexDelete < details.accomondations.length) {
            details.accomondations.splice(indexDelete, 1);
            setIndexDelete(null)
            updateDescription()
            updateAcc()
        } else {
            console.log("Index out of bounds");
        }
    }

    const handleSearch = (stationType) => {
        if (stationType !== "" && !load) {
            const searchTerm = stationType === 'departure' ? obj.departure_station : obj.arrival_station;
            let filtered = airports.filter(airport =>
                airport.name && airport.name.toLowerCase().includes(searchTerm.toLowerCase())
                || airport.city && airport.city.toLowerCase().includes(searchTerm.toLowerCase())
                || (airport.iata && airport.iata.includes(searchTerm.toUpperCase().trim()))
            );
            return filtered;
        } else {
            return;
        }
    }

    function getTimestampsForMonth(month, numberOfDays) {
        // Define the month names and their corresponding indices
        const months = {
            'JANUARY': 0,
            'FEBRUARY': 1,
            'MARCH': 2,
            'APRIL': 3,
            'MAY': 4,
            'JUNE': 5,
            'JULY': 6,
            'AUGUST': 7,
            'SEPTEMBER': 8,
            'OCTOBER': 9,
            'NOVEMBER': 10,
            'DECEMBER': 11
        };

        // Get the current year
        const year = new Date().getFullYear();

        // Get the month index from the month name
        const monthIndex = months[month.toUpperCase()];

        // Create the start date
        const startDate = new Date(year, monthIndex, 1);

        // Calculate the end date
        const endDate = new Date(year, monthIndex, numberOfDays);

        // Convert to timestamps
        const startTimestamp = startDate.getTime();
        const endTimestamp = endDate.getTime();

        return {
            startTimestamp: startTimestamp,
            endTimestamp: endTimestamp
        };
    }

    async function fetchDetails() {
        try {
            const { data } = await getRequest(APIS.ITINERARIES + "/" + id);
            if (data.status) {
                const { accomondations, start_date, end_date, tab_name, no_of_days } = data.data
                if (tab_name === 'MONTHS' && start_date === 0 && end_date === 0) {
                    setDetails(data.data)
                    setObj(data.data)
                    let OurMonth = moment(data.data.month).format('MMMM')
                    const { startTimestamp, endTimestamp } = getTimestampsForMonth(OurMonth, no_of_days);
                    setObj(prevDetails => ({
                        ...prevDetails, start_date: startTimestamp, end_date: endTimestamp
                    }));
                    setDetails(prevDetails => ({
                        ...prevDetails, start_date: startTimestamp, end_date: endTimestamp
                    }));
                    setLoad(false)
                    if (data.data.no_of_days !== data.data.accomondations.length) {
                        setPendingAcc(data.data.no_of_days - data.data.accomondations.length)
                    }

                } else {
                    setDetails(data.data)
                    setObj(data.data)
                    setLoad(false)
                    if (data.data.no_of_days !== data.data.accomondations.length) {
                        setPendingAcc(data.data.no_of_days - data.data.accomondations.length)
                    }
                }

                // setDescription(data?.data?.description)
                // setCharges(data?.data?.charges)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }


    const submitRequest = async (status) => {
        try {
            if (status === 1) {
                if (obj.no_of_days === 1) {
                    toast.error('Minimum duration for planning a trip is 2 days.')
                    return;
                }
                if (obj.charges == null) {
                    toast.error('Please add charges for this itinerary.')
                    return;
                }
                if (obj.no_of_days !== details.accomondations.length) {
                    toast.error('Please ensure the no of days and accommodation should be same')
                    return;
                }
                if (obj.charges < 10) {
                    toast.error('Charges should be greater then $10 for this itinerary.')
                    return;
                }
                if (obj.tab_name === 'DATES') {
                    if (obj.start_date === 0 || obj.end_date === 0) {
                        toast.error('Please set start or end date for itinerary.')
                        return;
                    }
                }
                if (status === 1 && obj.number_of_people === 0) {
                    toast.error('Number of people should not be zero.')
                    return;
                }
                if (obj.no_of_days == null) {
                    toast.error('Please add No of days for this itinerary.')
                    return;
                }
                if (obj.description === "") {
                    toast.error('Please add description for this itinerary.')
                    return;
                }
                if (obj.no_of_nights == null || obj.no_of_nights === 0) {
                    toast.error('Please add No of nights for this itinerary.')
                    return;
                }
                if (obj.departure_station === "") {
                    toast.error('Please add departure station it is required!')
                    return;
                }
                if (obj.arrival_station === "") {
                    toast.error('Please add arrival station it is required!')
                    return;
                }
                if (obj.start_date >= obj.end_date) {
                    toast.error('Please select proper dates.')
                    return;
                }
            }
            let id = details._id
            delete obj.status
            let updatedStatus = {
                status: status,
                ...obj
            }
            const { data } = await patchRequest(APIS.ITINERARIES + "/" + id, updatedStatus);
            if (data.status) {
                toaster(data.message, true);
                fetchDetails();
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false);
        }
    }

    const CancelRequest = async (status) => {
        try {
            let id = details._id
            delete obj.status
            let updatedStatus = {
                status: status,
                ...obj
            }
            const { data } = await patchRequest(APIS.ITINERARIES + "/" + id, updatedStatus);
            if (data.status) {
                toaster(data.message, true);
                fetchDetails();
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false);
        }
    }

    //set no of days acc to start date or end date
    const handleSaveDays = () => {
        const start = new Date(obj.start_date);
        const end = new Date(obj.end_date);
        // Calculate the difference in milliseconds
        const differenceInMillis = end - start;
        // Convert milliseconds to days (1 day = 1000 milliseconds * 60 seconds * 60 minutes * 24 hours)
        const daysDifference = Math.ceil(differenceInMillis / (1000 * 60 * 60 * 24));
        // Display the number of days
        setObj(prevObj => ({
            ...prevObj,
            no_of_days: daysDifference
        }));
        setObj(prevObj => ({
            ...prevObj,
            no_of_nights: daysDifference - 1
        }));
        setDetails(prevObj => ({
            ...prevObj,
            no_of_days: daysDifference
        }));
        setDetails(prevObj => ({
            ...prevObj,
            no_of_nights: daysDifference - 1
        }));
    }

    async function updateAcc() {
        try {
            const { data } = await patchRequest(APIS.ITINERARIES + "/" + id, obj);
            if (data.status) {
                fetchDetails()
                setEdit(false)
                toaster(data.message, true);
                handleClearStates()
            } else {
                toaster(data.message, false);
            }
            // if (parseInt(obj.no_of_days) > obj.accomondations.length) {
            //     toast.error('Please ensure that the number of days of the trip matches the number of accommodations.', { duration: 4000 })
            // }  else if (obj.no_of_nights > obj.no_of_days) {
            //     toast.error('No of nights should not greater then No of days')
            // } else {
            //     const { data } = await patchRequest(APIS.ITINERARIES + "/" + id, obj);
            //     if (data.status) {
            //         fetchDetails()
            //         setEdit(false)
            //         toaster(data.message, true);
            //         handleClearStates()
            //     } else {
            //         toaster(data.message, false);
            //     }
            // }
        } catch (err) {
            toaster(err, false)
        }
    }
    async function updateDescription() {
        try {
            if (obj.no_of_days === 1) {
                toast.error('Minimum duration for planning a trip is 2 days.')
                return;
            }
            if (obj.charges == null) {
                toast.error('Please add charges for this itinerary.')
                return;
            }
            if (obj.no_of_days !== details.accomondations.length) {
                toast.error('Please ensure the no of days and accommodation should be same')
                return;
            }
            if (obj.charges < 10) {
                toast.error('Charges should be greater then $10 for this itinerary.')
                return;
            }
            if (obj.tab_name === 'DATES') {
                if (obj.start_date === 0 || obj.end_date === 0) {
                    toast.error('Please set start or end date for itinerary.')
                    return;
                }
            }
            if (obj.description === "") {
                toast.error('Please add description for this itinerary.')
                return;
            }
            if (obj.number_of_people === 0) {
                toast.error('Number of people should not be zero.')
                return;
            }
            if (obj.no_of_days == null) {
                toast.error('Please add No of days for this itinerary.')
                return;
            }
            if (obj.no_of_nights == null || obj.no_of_nights === 0) {
                toast.error('Please add No of nights for this itinerary.')
                return;
            }
            if (obj.departure_station === "") {
                toast.error('Please add departure station it is required!')
                return;
            }
            if (obj.arrival_station === "") {
                toast.error('Please add arrival station it is required!')
                return;
            }
            if (obj.start_date >= obj.end_date) {
                toast.error('Please select proper dates.')
                return;
            }
            const { data } = await patchRequest(APIS.ITINERARIES + "/" + id, obj);
            if (data.status) {
                fetchDetails()
                setEdit(false)
                toaster(data.message, true);
                handleClearStates()
            } else {
                toaster(data.message, false);
            }
            // if (parseInt(obj.no_of_days) > obj.accomondations.length) {
            //     toast.error('Please ensure that the number of days of the trip matches the number of accommodations.', { duration: 4000 })
            // }  else if (obj.no_of_nights > obj.no_of_days) {
            //     toast.error('No of nights should not greater then No of days')
            // } else {
            //     const { data } = await patchRequest(APIS.ITINERARIES + "/" + id, obj);
            //     if (data.status) {
            //         fetchDetails()
            //         setEdit(false)
            //         toaster(data.message, true);
            //         handleClearStates()
            //     } else {
            //         toaster(data.message, false);
            //     }
            // }
        } catch (err) {
            toaster(err, false)
        }
    }
    const handleEdit = () => {
        setEdit(true)
    }
    const handleTabChange = (value) => {
        setObj(prevDetails => ({
            ...prevDetails, tab_name: value
        }));
        if (value === 'MONTHS') {
            setObj(prevDetails => ({
                ...prevDetails, month: 0
            }));
            return;
        } else if (value === 'DATES') {
            setObj(prevDetails => ({
                ...prevDetails, start_date: 0, end_date: 0
            }));
            return;
        }
    }
    const handleUploadComplete = (url) => {
        setObj(prevDetails => ({
            ...prevDetails, video: url
        }));
        setDetails(prevDetails => ({
            ...prevDetails, video: url
        }));
        setEdit(true)
    };


    const handleImageComplete = (newImages) => {
        setObj(prevDetails => ({
            ...prevDetails, photos: newImages
        }));
        setDetails(prevDetails => ({
            ...prevDetails, photos: newImages
        }));
        setEdit(true)
    };
    const handleClearStates = () => {
        setAcc([])
        setAddress('');
        setData({ tours_name: '', meals_name: '' });
        setMealsName([]);
        setLatitude(0);
        setLongitude(0);
    }
    useEffect(() => {
        fetchDetails()
    }, [id]);

    useEffect(() => {
        const departureFiltered = handleSearch('departure');
        setDeparture(departureFiltered);
    }, [obj, details.departure_station]);

    useEffect(() => {
        const arrivalFiltered = handleSearch('arrival');
        setArrival(arrivalFiltered);
        setTimeout(() => {
            handleSaveDays()
        }, 1000)
    }, [obj, details.arrival_station, details]);


    return (
        <>
            {load ?
                <Loader />
                :
                <>
                    <div className="pagetitle mb-5 mt-3" style={{ width: "fit-content" }}>
                        Itinerary Details
                    </div>
                    <div className="outer_div mt-4">
                        <div className='d-flex justify-content-end align-items-end'>
                            {details.status === 1 || details.status === 4 || details.status === 3 ? null :
                                <div className='editIcon' onClick={handleEdit}>
                                    <EditData />
                                </div>}
                        </div>
                        <div className={"row"}>
                            {details.is_created_admin ?
                                <div className="col-lg-2 col-md-2">
                                    <div className="profile_image text-center">
                                        <Image src={info.profile_pic !== "" ? info.profile_pic : "/user.jpg"} alt="" className="rounded-circle img-fluid" />
                                        <div className="name mt-3">{info.name}</div>
                                    </div>
                                </div>
                                :
                                <div className="col-lg-2 col-md-2">
                                    <div className="profile_image text-center">
                                        <Image src={details?.userId?.profile_pic !== "" ? details?.userId?.profile_pic : "/user.jpg"} alt="" className="rounded-circle img-fluid" />
                                        <div className="name mt-3">{details.userId.firstname + " " + details.userId.lastname}</div>
                                    </div>
                                </div>
                            }
                            <div className="col-lg-10 col-md-10">
                                {/* {details.status!==1 || details.status===4?null:
                                <div className='row'>
                                    <div className="detail_wrapper">
                                        <div className="name mb-3">
                                            If you want to cancel this itinerary, you can use the "Cancel" button.
                                        </div>
                                    </div>
                                    <div className='col-xs-3 col-sm-3 col-md-3 col-lg-3'>
                                        <button className='newBtn  mb-3' data-bs-toggle="modal" data-bs-target="#cancelBackdrop">Cancel</button>
                                    </div>
                                </div>} */}
                                <div className='row'>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Status</div>
                                            <div className="name mt-2">
                                                {details.status === 0 ?
                                                    <span className="badge pending">PENDING</span>
                                                    : details.status === 3 ?
                                                        <span className="badge ongoing">LIVE</span>
                                                        : details.status === 4 ?
                                                            <span className="badge cancelled">CANCELLED</span>
                                                            : details.status === 1 ?
                                                                <span className="badge accepted">ACCEPTED</span>
                                                                : <span className="badge rejected">REJECTED</span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Destination Address</div>
                                            <div className="name">
                                                    <Input type={"text"} value={obj.destination_address} disabled onChange={inputEvent} name="destination_address" />
                                                </div>
                                            {/* {!edit ?
                                                <div className="name mt-2">
                                                    {details?.destination_address !== "" ? details?.destination_address : "N/A"}
                                                </div>
                                                :
                                                <div className="name">
                                                    <Input type={"text"} value={obj.destination_address} disabled={edit ? false : true} onChange={inputEvent} name="destination_address" />
                                                </div>
                                            } */}
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        {/* {edit ?
                                            <div className="detail_wrapper">
                                                <div className="small_heading">
                                                    <div className='d-flex gap-2 align-items-center'>
                                                        Select -:
                                                        <div className="dropdown">
                                                            <div className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                {obj.tab_name}
                                                            </div>
                                                            <ul className="dropdown-menu p-3" aria-labelledby="dropdownMenuButton1">
                                                                <li onClick={() => handleTabChange('DATES')}>Dates</li>
                                                                <li className="mt-2 " onClick={() => handleTabChange('MONTHS')}>Months</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="detail_wrapper">
                                                <div className="small_heading">Travel should be</div>
                                                {!edit ?
                                                    <div className="name mt-2">{details?.tab_name && details?.tab_name !== "" ? details?.tab_name : "N/A"}</div>
                                                    :
                                                    <div className="name">
                                                        <Input type={"text"} value={obj.tab_name} disabled={edit ? false : true} onChange={inputEvent} name="tab_name" />
                                                    </div>
                                                }
                                            </div>
                                        } */}
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>

                                    </div>
                                    {obj.tab_name === 'MONTHS' ?
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4'>
                                            <div className="detail_wrapper">
                                                <div className="small_heading">Itinerary Travel by {obj.tab_name}</div>
                                                <div className="name mt-2">{details.month !== 0 ? moment(details.month).format('MMMM YYYY') : null}</div>
                                                {/* {!edit ?
                                                    null
                                                    :
                                                    <div className="name">
                                                        <Input type="Month" disabled={edit ? false : true} onChange={inputEvent} name="month" />
                                                    </div>
                                                } */}
                                            </div>
                                        </div>
                                        : null}
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Start Date {edit ? <>({moment(details.start_date).format('LLL')})</> : null}</div>
                                            {!edit ?
                                                <div className="name mt-2">{moment(details.start_date).format('LLL')}</div>
                                                :
                                                <div className="name">
                                                    <Input type={"datetime-local"} disabled={edit ? false : true} min={new Date().toISOString().slice(0, 16)} onChange={inputEvent} name="start_date" />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">End Date {edit ? <>({moment(details.end_date).format('LLL')})</> : null}</div>
                                            {!edit ?
                                                <div className="name mt-2">{moment(details.end_date).format('LLL')}</div>
                                                :
                                                <div className="name">
                                                    <Input type={"datetime-local"} disabled={edit ? false : true} min={obj.start_date ? moment(obj.start_date).format('YYYY-MM-DDTHH:mm') : new Date().toISOString().slice(0, 16)} onChange={inputEvent} name="end_date" />
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Departure Station</div>
                                            {!edit ?
                                                <div className="name mt-2">{details?.departure_station && details?.departure_station !== "" ? details?.departure_station : "N/A"}</div>
                                                :
                                                <div className="name">
                                                    <Input type={"text"} value={obj.departure_station} disabled={edit ? false : true} onChange={inputEvent} name="departure_station" />
                                                    <ul className="airports">
                                                        {obj.departure_station !== "" && departure.length > 0 ?
                                                            <div className='airport-List' >
                                                                {departure && departure.map(airport => (
                                                                    <li key={airport.iata} onClick={() => handleAddAirport(airport.name, airport.city, 'departure_station')}>
                                                                        {airport.name} - {airport.city}
                                                                    </li>
                                                                ))}
                                                            </div>
                                                            : null}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Arrival Station</div>
                                            {!edit ?
                                                <div className="name mt-2">{details?.arrival_station && details?.arrival_station !== "" ? details?.arrival_station : "N/A"}</div>
                                                :
                                                <div className="name">
                                                    <Input type={"text"} value={obj.arrival_station} disabled={edit ? false : true} onChange={inputEvent} name="arrival_station" />
                                                    <ul className="airports">
                                                        {obj.arrival_station !== "" && arrival.length > 0 ?
                                                            <div className='airport-List'>
                                                                {arrival && arrival.map(airport => (
                                                                    <li key={airport.iata} onClick={() => handleAddAirport(airport.name, airport.city, 'arrival_station')}>
                                                                        {airport.name} - {airport.city}
                                                                    </li>
                                                                ))}
                                                            </div>
                                                            : null}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Number of People</div>
                                            {!edit ?
                                                <div className="name mt-2">{details.number_of_people}</div>
                                                :
                                                <div className="name">
                                                    <Input type="text" value={obj.number_of_people} disabled={edit ? false : true} onChange={inputEvent} name="number_of_people" required />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Charges (Per person)</div>
                                            {!edit ?
                                                <div className="name mt-2">C$ {details.charges}</div>
                                                :
                                                <div className="name">
                                                    <Input type="text" maxLength={6} value={obj.charges} disabled={edit ? false : true} onChange={inputEvent} name="charges" required />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Description</div>
                                            {!edit ?
                                                <div className="name mt-2">{details?.description && details?.description !== "" ? details?.description : "N/A"}</div>
                                                :
                                                <div className="name mt-3">
                                                    <textarea type={"text"} rows={10} cols={30} value={obj.description} disabled={edit ? false : true} onChange={inputEvent} name="description" />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading mb-2">Video</div>
                                            {/* 
                                            {details.video !== "" ?
                                                <div className='imageSet'>
                                                    <video width="100%" height='auto' autoPlay playsInline style={{ pointerEvents: "none" }}>
                                                        <source src={details.video} type="video/mp4" />
                                                    </video>
                                                    <div className='icon' style={{ top: '7px', left: '330px' }} onClick={() => handleDeleteImages(obj.video, null)}>
                                                        <DeleteIcon />
                                                    </div>
                                                </div>
                                                :
                                                <div className='uploader' data-bs-toggle="modal" data-bs-target="#uploadVideoBackdrop">
                                                    <div className="detail_wrapper text-center">
                                                        <div className="small_heading">Upload Videos</div>
                                                        <div className="name mt-2"><VideosIcon /></div>
                                                    </div>
                                                </div>
                                            } */}
                                            {details.video !== "" ?
                                                <a className="name mt-2" style={{ textDecoration: 'underline' }} href={details.video} target="_blank" rel="noopener noreferrer">Click here for view</a>
                                                :
                                                <div className="name mt-2">Video not available for this itinerary</div>
                                            }
                                        </div>
                                    </div>
                                    {details.status === 0 ?
                                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4'>
                                            <div className="detail_wrapper">
                                                <div className="small_heading">Photos</div>
                                                {details.photos && details.photos.length > 0
                                                    ?
                                                    <div className='d-flex flex-wrap gap-3'>
                                                        {details.photos.map((element, index) => (
                                                            <div className='imageSet' key={index + 1}>
                                                                <img src={element} alt={element} className='itineraryImages' />
                                                                {details.status === 0 ? <div className='icon' onClick={() => handleDeleteImages(element, index)} >
                                                                    <DeleteIcon />
                                                                </div> : null}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    :
                                                    <div className='uploader' data-bs-toggle="modal" data-bs-target="#uploadImagesBackdrop">
                                                        <div className="detail_wrapper text-center">
                                                            <div className="small_heading">Upload Photos min(1) max(4)</div>
                                                            <div className="name mt-2"><ImagesIcon /></div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div> : null}
                                    <div className="detail_wrapper mt-4">
                                        <div className="name">The number of days should automatically adjust according to the start date and end date.</div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">No of days (Maximum 14 days)</div>
                                            <div className="name mt-2">
                                                {details?.no_of_days !== "" ? details?.no_of_days : "N/A"}
                                            </div>
                                            {/* {!edit ?
                                                <div className="name mt-2">
                                                    {details?.no_of_days !== "" ? details?.no_of_days : "N/A"}
                                                </div>
                                                :
                                                <div className="name">
                                                    <Input type="text" disabled maxLength={2} value={isNaN(obj.no_of_days) ? 0 : obj.no_of_days} list="days" onChange={inputEvent} name="no_of_days" />
                                                    <datalist id="days">
                                                        <option value={1} />
                                                        <option value={2} />
                                                        <option value={4} />
                                                        <option value={5} />
                                                        <option value={6} />
                                                        <option value={7} />
                                                        <option value={8} />
                                                        <option value={9} />
                                                        <option value={10} />
                                                        <option value={11} />
                                                        <option value={12} />
                                                        <option value={13} />
                                                        <option value={14} />
                                                    </datalist>
                                                </div>
                                            } */}
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">No of nights</div>
                                            <div className="name mt-2 text-lowercase">
                                                {details?.no_of_nights && details?.no_of_nights !== "" ? details?.no_of_nights : "N/A"}
                                            </div>
                                            {/* {!edit ?
                                                <div className="name mt-2 text-lowercase">
                                                    {details?.no_of_nights && details?.no_of_nights !== "" ? details?.no_of_nights : "N/A"}
                                                </div>
                                                :
                                                <div className="name">
                                                    <Input type="text" disabled maxLength={2} list="nights" value={isNaN(obj.no_of_nights) ? 0 : obj.no_of_nights} onChange={inputEvent} name="no_of_nights" />
                                                    <datalist id="nights">
                                                        <option value={1} />
                                                        <option value={2} />
                                                        <option value={4} />
                                                        <option value={5} />
                                                        <option value={6} />
                                                        <option value={7} />
                                                        <option value={8} />
                                                        <option value={9} />
                                                        <option value={10} />
                                                        <option value={11} />
                                                        <option value={12} />
                                                        <option value={13} />
                                                        <option value={14} />
                                                    </datalist>
                                                </div>
                                            } */}
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-3'>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Confirmation Number</div>
                                            <div className="name mt-2">
                                                {!edit ?
                                                    <div className="name mt-2">
                                                        {details?.confirmation_number !== "" ? details?.confirmation_number : "Not added"}
                                                    </div>
                                                    :
                                                    <div className="name">
                                                        <Input type={"text"} value={obj.confirmation_number} disabled={edit ? false : true} onChange={inputEvent} name="confirmation_number" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4'>
                                        {edit ? <div className='d-flex justify-content-start align-items-start'>
                                            <Button onClick={updateDescription} text="Save Changes" className="btn btn-success" disabled={obj.no_of_days === '' || obj.no_of_nights === ''} />
                                        </div> : null}
                                        {details.no_of_days !== details.accomondations.length ?
                                            <div className="alert alert-danger mt-3" role="alert">
                                                Please ensure that accommodation is adjusted according to the number of days of your trip!
                                            </div> : null}
                                        {details.status === 1 || details.status === 4 || details.status === 3 || edit || details.accomondations.length !== details.no_of_days ? null :
                                            <div className='d-flex justify-content-start align-items-start gap-2  mt-2 mb-2'>
                                                <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#acceptBackdrop">Accept</button>
                                                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#rejectBackdrop" >Reject</button>
                                            </div>
                                        }
                                    </div>

                                </div>
                                <div className='row'>
                                    <h5 className='mt-3'>Accommodations</h5>
                                    {details.accomondations && details.accomondations.length < details.no_of_days ?
                                        <>
                                            {details.accomondations && details.accomondations.map((element, index) => (
                                                <>
                                                    {indexEdit === index ?
                                                        <>
                                                            <div className="stepper">
                                                                <div className="step-container w-100">
                                                                    <div className="step-line active"></div>
                                                                    <div className='d-flex justify-content-start flex-column align-items-start w-100'>
                                                                        <p className="step active w-100 mt-2">Edit Day {indexEdit + 1} accommodation
                                                                            <br />
                                                                            <div className='mt-2'>
                                                                                <label>Destination Address</label>
                                                                                <input type="text" ref={inputRef} placeholder="Search accommodations" name="place" onChange={handleSearchLoc} value={searchLoc} />
                                                                                <div className='row'>
                                                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                                                        <label>Meals</label>
                                                                                        <input type='text' placeholder='Add Meal' onChange={handleAddData} name='meals_name' value={data.meals_name} />
                                                                                        <div className='meals'>
                                                                                            {meals_name && meals_name.map((element, index) => (
                                                                                                <div className='chip' key={index + 1}>
                                                                                                    <div>{element}</div>
                                                                                                    <div style={{ cursor: "pointer" }} onClick={() => handleRemoveMeal(index)}><CancelChip /></div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                        {data.meals_name !== '' ? <Button onClick={handlePushMeals} text="Save Meal" className="btn btn-success mt-3" /> : null}
                                                                                    </div>
                                                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                                                        <label>Tours</label>
                                                                                        <input type='text' placeholder='Name of tour or provider' onChange={handleAddData} name='tours_name' value={data.tours_name} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </p>
                                                                        <div className='mt-2 d-flex align-items-center gap-3 mb-3'>
                                                                            <button type="button" className="btn btn-secondary" onClick={() => setIndexEdit(null)}>Cancel</button>
                                                                            <button type="button" className="btn btn-success" disabled={searchLoc === '' || meals_name.length === 0 || data.tours_name === ''} onClick={handleSaveAccommodation}>Save</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="stepper" key={index + 1}>
                                                                <div className="step-container w-100">
                                                                    <div className="step-line active"></div>
                                                                    <div className='d-flex justify-content-between align-items-start w-100'>
                                                                        <div className="step active w-100 mt-2">Day {index + 1}
                                                                            <br />
                                                                            <div className='d-flex justify-content-start align-items-start gap-2 flex-column w-100'>
                                                                                <div className='accommodation-text'>
                                                                                    <div className='d-flex justify-content-start align-items-start gap-2 flex-column'>
                                                                                        <div className="small_heading">Address</div>
                                                                                        <div className="name">
                                                                                            <div className='mealChip'>
                                                                                                <Location />
                                                                                                <div>{element.accomondation_address}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='row w-100'>
                                                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                                                        <div className='accommodation-text'>
                                                                                            <div className='d-flex justify-content-start align-items-start gap-2 flex-column'>
                                                                                                <div className="small_heading">Meals</div>
                                                                                                <div className="name">
                                                                                                    {element.meals?.map(item =>
                                                                                                        <div className='mealChip'>
                                                                                                            <MealIcon />
                                                                                                            <div>{item}</div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                                                        <div className='accommodation-text'>
                                                                                            <div className='d-flex justify-content-start align-items-start gap-2 flex-column'>
                                                                                                <div className="small_heading">Tours</div>
                                                                                                <div className="name">
                                                                                                    <div className='mealChip'>
                                                                                                        <TourCar />
                                                                                                        <div>{element.tours}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {details.status === 1 || details.status === 3 || details.status === 4 ? null :
                                                                            <div className='mt-2 d-flex align-items-center gap-3'>
                                                                                <div className='pointer' onClick={() => handleEditAccommodation(element, index)}><EditIcon /></div>
                                                                                <div className='pointer' onClick={() => setIndexDelete(index)} data-bs-toggle="modal" data-bs-target="#deleteBackdrop"><DeleteIcon /></div>
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            ))}
                                        </>

                                        :
                                        <>
                                            {details.accomondations && details.accomondations.slice(0, -1).map((element, index) => (
                                                <>
                                                    {indexEdit === index ?
                                                        <>
                                                            <div className="stepper">
                                                                <div className="step-container w-100">
                                                                    <div className="step-line active"></div>
                                                                    <div className='d-flex justify-content-start flex-column align-items-start w-100'>
                                                                        <p className="step active w-100 mt-2">Edit Day {indexEdit + 1} accommodation
                                                                            <br />
                                                                            <div className='mt-2'>
                                                                                <label>Destination Address</label>
                                                                                <input type="text" ref={inputRef} placeholder="Search accommodations" name="place" onChange={handleSearchLoc} value={searchLoc} />
                                                                                <div className='row'>
                                                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                                                        <label>Meals</label>
                                                                                        <input type='text' maxLength={100} placeholder='Add Meal' onChange={handleAddData} name='meals_name' value={data.meals_name} />
                                                                                        <label>Max length (100)</label>
                                                                                        <div className='meals'>
                                                                                            {meals_name && meals_name.map((element, index) => (
                                                                                                <div className='chip' key={index + 1}>
                                                                                                    <div>{element}</div>
                                                                                                    <div style={{ cursor: "pointer" }} onClick={() => handleRemoveMeal(index)}><CancelChip /></div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                        {data.meals_name !== '' ? <Button onClick={handlePushMeals} text="Save Meal" className="btn btn-success mt-3" /> : null}
                                                                                    </div>
                                                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                                                        <label>Tours</label>
                                                                                        <input type='text' maxLength={300} placeholder='Name of tour or provider' onChange={handleAddData} name='tours_name' value={data.tours_name} />
                                                                                        <label>Max length (300)</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </p>
                                                                        <div className='mt-2 d-flex align-items-center gap-3 mb-3'>
                                                                            <button type="button" className="btn btn-secondary" onClick={() => setIndexEdit(null)}>Cancel</button>
                                                                            <button type="button" className="btn btn-success" disabled={searchLoc === '' || meals_name.length === 0 || data.tours_name === ''} onClick={handleSaveAccommodation}>Save</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="stepper" key={index + 1}>
                                                                <div className="step-container w-100">
                                                                    <div className="step-line active"></div>
                                                                    <div className='d-flex justify-content-between align-items-start w-100'>
                                                                        <div className="step active w-100 mt-2">Day {index + 1}
                                                                            <br />
                                                                            <div className='d-flex justify-content-start align-items-start gap-2 flex-column w-100'>
                                                                                <div className='accommodation-text'>
                                                                                    <div className='d-flex justify-content-start align-items-start gap-2 flex-column'>
                                                                                        <div className="small_heading">Address</div>
                                                                                        <div className="name">
                                                                                            <div className='mealChip'>
                                                                                                <Location />
                                                                                                <div>{element.accomondation_address}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='row w-100'>
                                                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                                                        <div className='accommodation-text'>
                                                                                            <div className='d-flex justify-content-start align-items-start gap-2 flex-column'>
                                                                                                <div className="small_heading">Meals</div>
                                                                                                <div className="name">
                                                                                                    {element.meals?.map(item =>
                                                                                                        <div className='mealChip'>
                                                                                                            <MealIcon />
                                                                                                            <div>{item}</div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                                                        <div className='accommodation-text'>
                                                                                            <div className='d-flex justify-content-start align-items-start gap-2 flex-column'>
                                                                                                <div className="small_heading">Tours</div>
                                                                                                <div className="name">
                                                                                                    <div className='mealChip'>
                                                                                                        <TourCar />
                                                                                                        <div>{element.tours}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {details.status === 1 || details.status === 4 || details.status === 3 ? null :
                                                                            <div className='mt-2 d-flex align-items-center gap-3'>
                                                                                <div className='pointer' onClick={() => handleEditAccommodation(element, index)}><EditIcon /></div>
                                                                                <div className='pointer' onClick={() => setIndexDelete(index)} data-bs-toggle="modal" data-bs-target="#deleteBackdrop"><DeleteIcon /></div>
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            ))}

                                            {details.accomondations && details.accomondations.length === details.no_of_days
                                                ?
                                                <div className="stepper">
                                                    <div className="step-container w-100">
                                                        <div className="step-line active"></div>
                                                        <div className='d-flex justify-content-between align-items-start w-100'>
                                                            <div className="step active w-100 mt-2">Day {details.accomondations && details.accomondations.length}
                                                                <br />
                                                                <div className='d-flex justify-content-start align-items-start gap-2 flex-column w-100'>
                                                                    <div className='accommodation-text'>
                                                                        <div className='d-flex justify-content-start align-items-start gap-2 flex-column'>
                                                                            {/* <div className="small_heading">Next Accommodation</div> */}
                                                                            <div className="name">
                                                                                <div className='mealChip'>
                                                                                    <Hotel />
                                                                                    <div>Check out of the hotel.</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                        </>}
                                </div>
                                <div className='row'>
                                    <div className='mt-4'></div>
                                    {acc && acc.map((element, index) => (
                                        <div key={index + 1}>
                                            <label>Destination Address</label>
                                            <input type="text" ref={inputRef} placeholder="Search accommodations" name="place" id="place" onChange={handleSearchLoc} />
                                            <div className='row'>
                                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                    <label>Meals</label>
                                                    <input type='text' maxLength={100} placeholder='Add Meal' onChange={handleAddData} name='meals_name' value={data.meals_name} />
                                                    <label>Max length (100)</label>
                                                    <div className='meals'>
                                                        {meals_name && meals_name.map((element, index) => (
                                                            <div className='chip' key={index + 1}>
                                                                <div>{element}</div>
                                                                <div style={{ cursor: "pointer" }} onClick={() => handleRemoveMeal(index)}><CancelChip /></div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {data.meals_name !== '' ? <Button onClick={handlePushMeals} text="Save Meal" className="btn btn-success mt-3" /> : null}
                                                </div>
                                                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                                                    <label>Tours</label>
                                                    <input type='text' maxLength={300} placeholder='Name of tour or provider' onChange={handleAddData} name='tours_name' value={data.tours_name} />
                                                    <label>Max length (300)</label>
                                                </div>
                                            </div>
                                        </div>

                                    ))}

                                    {parseInt(obj.no_of_days) > details.accomondations.length || details.no_of_days > details.accomondations.length ?

                                        <>
                                            {acc.length === 0 ?
                                                <>
                                                    <div onClick={handleMore} style={{ cursor: "pointer" }} className='d-flex justify-content-start align-items-center mt-4'>
                                                        <More /> &nbsp; &nbsp;Click here to add accommodation.
                                                    </div>

                                                </>
                                                :
                                                <>
                                                    <div className=' mt-2 d-flex flex-column justify-content-start align-items-start'>
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onClick={handleCheckData} />
                                                            <label className="form-check-label mt-1" for="flexCheckDefault">Please confirm all details are correct before submitting.</label>
                                                        </div>
                                                        <Button onClick={updateAcc} text="Save" className=" mt-2 btn btn-success" disabled={searchLoc === '' || meals_name.length === 0 || data.tours_name === '' || checked === false} />
                                                    </div>
                                                </>
                                            }
                                        </> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }
            <div className="modal fade" id="deleteBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Deleting accommodation!</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5>Are you sure you want to delete this accommodation? Deleting this accommodation will affect your trip!</h5>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={() => deleteAccommodationObject(indexDelete)} data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="cancelBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className='p-3'>
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Are you sure want cancel this itinerary?
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='d-flex justify-content-end align-items-center gap-3 p-3'>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => CancelRequest(4)}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="acceptBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className='p-3'>
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Are you sure want accept this itinerary request?
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='d-flex justify-content-end align-items-center gap-3 p-3'>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => submitRequest(1)}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="rejectBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className='p-3'>
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Are you sure want reject this itinerary request?
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='d-flex justify-content-end align-items-center gap-3 p-3'>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => submitRequest(2)}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="uploadVideoBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Upload video here.</h5>
                        </div>
                        <div className="modal-body">
                            <UploadVideo onUpload={handleUploadComplete} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Close</button>
                            <button type="button" className="newBtn" disabled={obj.video === ''} data-bs-dismiss="modal">Done</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="uploadImagesBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Upload images here min(1) max(4).</h5>
                        </div>
                        <div className="modal-body">
                            <UploadImages onUpload={handleImageComplete} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  >Close</button>
                            <button type="button" className="newBtn" disabled={obj.photos && obj.photos.length === 0} data-bs-dismiss="modal">Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItineraryDetails